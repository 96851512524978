<template>
  <span
    class="wr-text__small text-capitalize d-block"
    :class="{
      'wr-text--danger': state === 'danger',
      'wr-text--success': state === 'success',
    }"
  >
    <template v-if="typeof message === 'string'">
      {{ message }}
    </template>
    <template v-else-if="Array.isArray(message)">
      <div v-for="(msg, index) in message" :key="index">{{ msg }}</div>
    </template>
  </span>
</template>

<script>
export default {
  name: "InlineMessage",
  props: {
    message: {
      type: [String, Array],
      default: "",
    },
    state: {
      type: String,
      default: "danger",
    },
  },
};
</script>

<style></style>
