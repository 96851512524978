import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/store";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store?.state?.auth?.isAuthenticated) {
      next({
        name: "Login",
      });
    } else {
      const userRole = store.state.auth.user?.role;
      const userPermissions = userRole?.permissions.map(
        (permission) => permission.label
      );
      if (!userRole || !userPermissions) {
        next({
          name: "Unauthorized",
        });
      } else {
        const requiredPermissions = to.meta.permissions;
        if (!requiredPermissions) {
          next();
        }

        if (
          requiredPermissions.some((permission) =>
            userPermissions.includes(permission)
          )
        ) {
          next();
        } else {
          next({
            name: "Unauthorized",
          });
        }
      }
    }
  } else {
    next();
  }
});

export default router;
