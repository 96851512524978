<template>
  <div class="wr-file-item my-3 wr-bg--white">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center">
        <div class="wr-text__h3 wr-text--success me-3">
          <i class="bi bi-folder"></i>
        </div>
        <div>
          <div class="wr-text__small wr-text--dark wr-text--semibold">
            {{ shortenStringAtCenter(file.filename, 24) }}
          </div>
          <div class="wr-text__smaller wr-text--dark opacity-75">
            {{ file.filesize }}
          </div>
        </div>
      </div>
      <a
        href="javascript:void(0)"
        class="wr-text__body text-decoration-none text-danger"
        @click="removeFile"
        v-if="hasRemove"
      >
        <i class="bi bi-trash2-fill"></i>
      </a>
      <a
        href="javascript:void(0)"
        class="wr-text__body--larger text-decoration-none wr-text--success"
        @click="downloadFile"
        v-if="hasDownload"
      >
        <i class="bi bi-cloud-download"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { shortenStringAtCenter, startDownload } from "@/helpers";
export default {
  name: "FileUploadItem",
  props: {
    file: {
      type: Object,
      required: true,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    hasDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shortenStringAtCenter,
    removeFile() {
      this.$emit("removeFile", this.file);
    },
    downloadFile() {
      startDownload(this.file);
    },
  },
};
</script>

<style scoped>
.wr-file-item {
  padding: 0.85rem;
  border: 1px solid var(--wr-grey-2);
  border-radius: 8px;
}
.wr-file-item .wr-text__h3 span {
  font-size: 1.5rem;
}
</style>
