<template>
  <div
    class="d-flex align-items-center wr-text__small wr-text--dark wr-text--medium opacity-50"
  >
    <div v-for="(item, index) in crumbs" :key="index">
      {{ item }}

      <template v-if="index !== crumbs.length - 1">
        <i class="bi bi-chevron-double-right wr-text__tiny mx-1"></i>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavCrumbs",
  props: {
    crumbs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.separator {
  margin: 0 5px;
}
</style>
