<template>
  <div class="d-flex align-items-center cursor--pointer" @click="handleCheck">
    <div
      class="wr-checkbox me-2"
      :class="{
        'wr-checkbox--checked': checked,
      }"
    >
      <i class="bi bi-check-lg" v-if="checked"></i>
    </div>

    <label class="wr-text__small wr-text--dark cursor--pointer">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isChecked,
    };
  },
  methods: {
    handleCheck() {
      if (this.isDisabled) return;
      this.checked = !this.checked;
      this.$emit("checkedEvent", this.checked);
    },
  },
  watch: {
    isChecked(newVal, oldVal) {
      this.checked = newVal;
    },
  },
};
</script>

<style>
.wr-checkbox {
  min-height: 22px;
  min-width: 22px;
  max-height: 22px;
  max-width: 22px;
  border-radius: 4px;
  color: var(--wr-white);
  background: var(--wr-grey-2);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 14px;
  border: 1px solid var(--wr-grey-2);
  transition: 0.5s ease-in-out;
}
.wr-checkbox.wr-checkbox--checked {
  background: var(--wr-primary);
}
</style>
