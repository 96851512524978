export const supportedCards = [
  {
    name: "Visa",
    value: "visa",
    iconPath: require("@/assets/icons/visa.svg"),
  },
  {
    name: "Mastercard",
    value: "masterCard",
    iconPath: require("@/assets/icons/mastercard.svg"),
  },
  {
    name: "Verve",
    value: "verve",
    iconPath: require("@/assets/icons/verve.svg"),
  },
];
