<template>
  <div
    class="wr-loader"
    :class="{
      'wr-loader--button': variant === 'button',
      'wr-loader--section': variant === 'section',
      'wr-loader--center': position === 'center',
      'wr-loader--large': size === 'large',
    }"
  >
    <div class="dot-loader"></div>
    <div class="dot-loader dot-loader--2"></div>
    <div class="dot-loader dot-loader--3"></div>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
  props: {
    variant: {
      type: String,
      default: "button",
    },
    position: {
      type: String,
      default: "auto",
    },
    size: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style scoped>
.wr-loader {
  position: relative;
  display: flex;
  margin: 0 8px;
}
.wr-loader .dot-loader {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: 850ms grow ease-in-out infinite;
  animation: 850ms grow ease-in-out infinite;
}

.wr-loader .dot-loader--2 {
  -webkit-animation: 850ms grow ease-in-out infinite 0.15555s;
  animation: 850ms grow ease-in-out infinite 0.15555s;
  margin: 0 6px;
}
.wr-loader .dot-loader--3 {
  -webkit-animation: 850ms grow ease-in-out infinite 0.3s;
  animation: 850ms grow ease-in-out infinite 0.3s;
}
.wr-loader.wr-loader--button .dot-loader {
  background-color: #fff;
}
.wr-loader.wr-loader--section .dot-loader {
  background-color: var(--wr-primary);
}
.wr-loader.wr-loader--large .dot-loader {
  height: 30px;
  width: 30px;
  background-color: var(--wr-primary);
}
.wr-loader.wr-loader--center {
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}
@-webkit-keyframes grow {
  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes grow {
  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
