<template>
  <div
    class="wr-item-wrapper"
    :class="{
      'wr-item-wrapper--fluid': size == 'fluid',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DashboardItemWrapper",
  props: {
    size: {
      type: String,
      default: "large",
    },
  },
};
</script>

<style scoped>
.wr-item-wrapper {
  border-radius: 24px;
  background: var(--wr-white);
  width: 100%;
  padding: 2.5rem;
  height: calc(90vh - var(--wr-header-height));
  overflow-y: auto;
}
.wr-item-wrapper.wr-item-wrapper--fluid {
  height: auto;
}
@media screen and (max-width: 992px) {
  .wr-item-wrapper {
    padding: 2rem 1.5rem !important;
  }
}
</style>
