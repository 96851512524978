<template>
  <div
    class="wr-user-avatar"
    :class="{
      'wr-user-avatar--large': size == 'large',
      'wr-user-avatar--2x': size == '2x',
      'wr-user-avatar--smaller': size == 'smaller',
    }"
  >
    <img
      :src="imagePath"
      v-img-loaded="require('@/assets/images/defaults/avatar_placeholder.svg')"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    imagePath: "",
    size: "small",
  },
};
</script>

<style>
.wr-user-avatar {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-self: center;
}
.wr-user-avatar img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.wr-user-avatar.wr-user-avatar--large {
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  max-width: 120px;
}
.wr-user-avatar.wr-user-avatar--2x {
  min-height: 150px;
  max-height: 150px;
  min-width: 150px;
  max-width: 150px;
}
.wr-user-avatar.wr-user-avatar--smaller {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
}
</style>
