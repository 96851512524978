<template>
  <div class="wr-side-modal" :class="[modalOpen && 'wr-side-modal--open']">
    <div class="wr-side-modal__content p-3 p-lg-5 py-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideModal",
  props: {
    modalOpen: {
      type: Boolean,
    },
  },
  methods: {
    closeSideModal() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
