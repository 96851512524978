<template>
  <div :class="statusClasses" :style="statusStyles" class="p-2">
    <div
      class="wr-status__indicator me-1"
      :style="{ background: getProjectStatus(status)?.colorCode }"
    ></div>
    <span class="text-capitalize">{{ getProjectStatus(status)?.name }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusItem",
  props: {
    status: {
      type: String,
      default: "pending",
    },
    isBadge: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowedStatus: [
        { name: "pending", value: "pending", colorCode: "#F2994A" },
        { name: "paid", value: "paid", colorCode: "#219653" },
        { name: "accepted", value: "accepted", colorCode: "#219653" },
        { name: "active", value: "active", colorCode: "#2F80ED" },
        { name: "review", value: "review", colorCode: "#0CBC9D" },
        { name: "shipped", value: "shipped", colorCode: "#9B51E0" },
        { name: "dispute", value: "dispute", colorCode: "#EB5757" },
        { name: "Suspended", value: "suspended", colorCode: "#EB5757" },
        { name: "Rejected", value: "rejected", colorCode: "#EB5757" },
        { name: "Unpaid", value: "unpaid", colorCode: "#EB5757" },
        { name: "closed", value: "closed", colorCode: "#999" },
        { name: "disabled", value: "disabled", colorCode: "#c1c1c1" },
        { name: "draft", value: "draft", colorCode: "#c1c1c1" },
        { name: "inactive", value: "inactive", colorCode: "#c1c1c1" },
      ],
    };
  },
  computed: {
    statusClasses() {
      return ["wr-status", { "px-2 py-1 wr-text__tiny": this.isSmall }];
    },
    statusStyles() {
      if (this.isBadge) {
        return {
          background: `${this.getProjectStatus(this.status)?.colorCode}20`,
          color: this.getProjectStatus(this.status)?.colorCode,
          padding: 0,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    getProjectStatus(status) {
      const data = this.allowedStatus.find((val) => val.value === status);
      return data || { name: "unknown", value: "unknown", colorCode: "#999" };
    },
  },
};
</script>

<style scoped>
.wr-status {
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.wr-status .wr-status__indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
</style>
