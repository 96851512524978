const projectStatus = [
  {
    name: "all",
    value: "all",
    colorCode: "#219653",
  },
  {
    name: "pending",
    value: "pending",
    colorCode: "#F2994A",
  },
  {
    name: "active",
    value: "active",
    colorCode: "#2F80ED",
  },
  {
    name: "review",
    value: "review",
    colorCode: "#0CBC9D",
  },
  {
    name: "shipped",
    value: "shipped",
    colorCode: "#9B51E0",
  },
  {
    name: "dispute",
    value: "dispute",
    colorCode: "#EB5757",
  },
  {
    name: "closed",
    value: "closed",
    colorCode: "#999",
  },
  {
    name: "Unassigned",
    value: "unassigned",
    colorCode: "#ccc",
  },
];
export const paymentStatus = [
  {
    name: "all",
    value: "all",
    colorCode: "#888",
  },
  {
    name: "pending",
    value: "pending",
    colorCode: "#F2994A",
  },
  {
    name: "paid",
    value: "paid",
    colorCode: "#219653",
  },
  {
    name: "failed",
    value: "failed",
    colorCode: "#eb5757",
  },
];
export default projectStatus;
