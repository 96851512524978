export const allPermissions = [
  "manage_admins",
  "manage_users",
  "manage_finance",
  "manage_settings",
  "manage_projects",
  "can_write",
];
export const adminPermissions = [
  "manage_users",
  "manage_finance",
  "manage_settings",
  "manage_projects",
];
export const financePermissions = ["manage_finance"];
export const writerPermissions = ["can_write"];
