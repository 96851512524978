import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";
import {
  auth,
  theme,
  overview,
  projects,
  customers,
  payments,
  staff,
  settings,
  messenger,
} from "./modules";
export default createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    theme,
    overview,
    projects,
    customers,
    payments,
    staff,
    settings,
    messenger,
  },
});
