<template>
  <div
    class="d-flex"
    :class="{
      'justify-content-start': position == 'start',
      'justify-content-center': position == 'center',
      'justify-content-end': position == 'end',
    }"
  >
    <img src="@/assets/brand/logo.svg" class="wr-brand-logo" alt="Logo" />
  </div>
</template>

<script>
export default {
  name: "AppLogo",
  props: {
    position: {
      type: String,
      default: "start",
    },
  },
};
</script>

<style scoped>
.wr-brand-logo {
  display: block;
  max-width: 180px;
}
</style>
