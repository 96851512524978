import axiosService from "@/services/axiosService";
import router from "@/router";
import { authPaths } from "@/router/path.route";
const state = {
  user: null,
  isAuthenticated: false,
  accessToken: null,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  getUser: (state) => state.user,
  getTwoFaState: (state) =>
    (state.user &&
      state.user.twoFa &&
      state.user.twoFa.isEnabled &&
      !state.twoFaConfirmed) ||
    false,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setRefToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setTwofa(state, isConfirmed) {
    state.twoFaConfirmed = isConfirmed;
  },
};

const actions = {
  async LOGIN_USER({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post("/admin/auth/login", payload);
      commit("setUser", data.data?.user);
      commit("setAccessToken", data.data?.token);
      // commit("setRefToken", data.data?.refreshToken);
      commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_USER({ commit, state }) {
    try {
      const { data } = await axiosService.get("/admin/account/profile");
      commit("setUser", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async LOGOUT_USER({ commit, state }, payload) {
    try {
      router.push(authPaths["login"]);
      setTimeout(() => {
        commit("setUser", null);
        commit("setAccessToken", null);
        commit("setRefToken", null);
        commit("setIsAuthenticated", false);
        commit("setTwofa", false);
      }, 2000);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
