import axiosService from "@/services/axiosService";
const state = {};

const getters = {};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  getters,
  mutations,
  actions,
};
