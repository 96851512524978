<template>
  <div
    class="wr-modal"
    :class="{
      'wr-modal--center': position == 'center',
    }"
  >
    <div
      class="wr-modal__content"
      :class="{
        'wr-modal__content--large': contentSize == 'large',
        'wr-modal__content--medium': contentSize == 'medium',
        'wr-modal__content--small': contentSize == 'small',
        'wr-modal__content--smaller': contentSize == 'smaller',
      }"
    >
      <div
        class="wr-modal__content-controls d-flex justify-content-center flex-column"
        :class="{
          'align-items-center': alignment == 'center',
          'align-items-start': alignment == 'start',
        }"
      >
        <div
          class="d-flex my-2"
          v-if="showLogo"
          :class="{
            'justify-content-center': alignment == 'center',
            'justify-content-start': alignment == 'start',
          }"
        >
          <AppLogo />
        </div>
        <div class="w-100">
          <div
            class="wr-text__h3 wr-text--bold wr-text--dark"
            :class="{
              'text-center': alignment == 'center',
              'text-start': alignment == 'start',
            }"
          >
            {{ modalTitle }}
          </div>
          <div
            :class="{
              'text-center mx-auto': alignment == 'center',
              'text-start': alignment == 'start',
            }"
          >
            <p
              class="wr-text__body--14 wr-text--dark opacity-75"
              v-if="modalDescription"
            >
              {{ modalDescription }}
            </p>
          </div>
        </div>
        <a
          v-if="hasClose"
          href="javascript:void(0)"
          class="wr-modal__close"
          @click="emitClose"
        >
          <i class="bi bi-x-circle-fill"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    hasClose: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    contentSize: {
      type: String,
      default: "medium",
    },
    modalTitle: {
      type: String,
      default: "",
    },
    alignment: {
      type: String,
      default: "center",
    },
    modalDescription: {
      type: String,
    },
    position: {
      type: String,
      default: "top",
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
