<template>
  <div>
    <modal-wrapper
      v-if="pickerIsOpen"
      contentSize="smaller"
      @close="handleClose"
    >
      <VueDatePicker
        :enable-time-picker="false"
        auto-apply
        @update:model-value="handleDate"
        @closed="handleClose"
        v-model="date"
        ref="datepicker"
        :min-date="new Date()"
      ></VueDatePicker>
    </modal-wrapper>
    <div class="dropdown wr-dropdown">
      <button
        class="wr-button wr-button__input justify-content-start w-100"
        type="button"
        @click="openDatePicker"
      >
        <i class="bi bi-calendar3 me-2"></i>
        <span v-if="date">{{ advancedDateFormat(date, "Do MMMM, YYYY") }}</span>
        <span v-else class="opacity-50">Choose Date</span>
      </button>
    </div>
  </div>
</template>

<script>
import { formatDateOnly, advancedDateFormat } from "@/helpers";
export default {
  name: "DatePicker",
  data() {
    return {
      date: "",
      pickerIsOpen: false,
    };
  },
  methods: {
    formatDateOnly,
    advancedDateFormat,
    async openDatePicker() {
      this.pickerIsOpen = true;
      await this.$nextTick();
      this.$refs.datepicker.openMenu();
    },
    handleClose() {
      this.pickerIsOpen = false;
    },
    async handleDate(date) {
      this.date = formatDateOnly(date, "DD-MM-YYYY");
      this.$emit("dateSelected", date);
    },
  },
};
</script>

<style></style>
