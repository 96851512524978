import axiosService from "@/services/axiosService";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_ALL_PAYMENTS({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
        end_date = "",
        start_date = "",
        side = "",
        userId = "",
      } = payload ?? {};
      let requestUrl = `/admin/invoice/all`;
      if (side == "user") {
        requestUrl = `/admin/user/${userId}/invoice`;
      }
      const { data } = await axiosService.get(requestUrl, {
        params: {
          status,
          page,
          per_page,
          search,
          end_date,
          start_date,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PAYMENT_SUMMARY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/invoice/summary`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async EXPORT_PAYMENT_AS_CSV({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/invoice/export`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  getters,
  mutations,
  actions,
};
