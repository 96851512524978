import axiosService from "@/services/axiosService";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_ALL_HISTORY({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
        archives = "",
      } = payload ?? {};
      const { data } = await axiosService.get(`/admin/project/history/all`, {
        params: {
          status,
          page,
          per_page,
          search,
          archives,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_HISTORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin/project/history/${payload}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_INTERACTIONS({ commit, state }, payload) {
    try {
      const { page = 1, per_page = 10, id } = payload ?? {};
      const { data } = await axiosService.get(
        `/admin/project/history/${id}/interactions`,
        {
          params: {
            page,
            per_page,
          },
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_ALL_COMMENTS({ commit, state }, payload) {
    try {
      const { page = 1, per_page = 10, projectId } = payload ?? {};
      const { data } = await axiosService.get(`/admin/comments/${projectId}`, {
        params: {
          page,
          per_page,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async NEW_COMMENT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/comments/${payload.projectId}/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
