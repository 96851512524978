<template>
  <div class="wr-confirmation">
    <div class="wr-confirmation__content text-center">
      <div class="wr-lottie-item mx-auto mb-4">
        <LottieAnimation
          ref="anim"
          :animationData="require('@/assets/lottie/app_info.json')"
          :speed="1"
          :autoPlay="true"
        />
      </div>
      <h1 class="wr-text__h4 wr-text--dark">Confirmation</h1>
      <p class="wr-text__body wr-text--dark">{{ message }}</p>
      <div class="d-flex align-items-center justify-content-center mt-5">
        <button
          @click="confirm(false)"
          class="wr-button wr-button--small wr-bg--danger-muted wr-text--danger"
        >
          No <i class="bi bi-x-circle-fill ms-2"></i>
        </button>
        <div class="mx-2"></div>
        <button
          @click="confirm(true)"
          class="wr-button wr-button--small wr-bg--success-muted wr-text--success"
        >
          Yes <i class="bi bi-check-circle-fill ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalWrapper from "./ModalWrapper.vue";
import { LottieAnimation } from "lottie-web-vue";
export default {
  components: {
    ModalWrapper,
    LottieAnimation,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const confirm = (choice) => {
      props.onConfirm(choice);
    };

    return {
      confirm,
    };
  },
};
</script>
