import axiosService from "@/services/axiosService";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_OVERVIEW({ commit, state }, payload) {
    try {
      const { start_date = "", end_date = "" } = payload ?? {};
      const { data } = await axiosService.get(
        `/admin/overview/stats?start_date=${start_date}&end_date=${end_date}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_REVENUE_STATS({ commit, state }, payload) {
    try {
      const { start_date = "", end_date = "" } = payload ?? {};
      const { data } = await axiosService.get(
        `/admin/overview/revenue/stats?start_date=${start_date}&end_date=${end_date}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
