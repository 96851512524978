import io from "socket.io-client";
import { SOCKET_URL } from "@/config";
const socket = io(SOCKET_URL);
socket.on("connect", () => {
  console.log("CONNECTED_TO_SERVER");
});
socket.on("error", (error) => {
  console.log("An error Occurred", error);
});

export default socket;
