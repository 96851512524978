<template>
  <nav
    v-if="total_pages > 1"
    class="wr-pagination d-flex justify-content-end mt-4"
  >
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: page === 1 }">
        <button class="page-link page-prev" @click="previousPage">
          <i class="bi bi-chevron-left"></i>
        </button>
      </li>
      <li
        v-for="currentPage in pageList"
        :key="currentPage"
        class="page-item"
        :class="{
          active: currentPage === page,
          'disabled-page': currentPage === '...',
        }"
      >
        <button class="page-link" @click="goToPage(currentPage)">
          {{ currentPage }}
        </button>
      </li>
      <li class="page-item" :class="{ disabled: page === total_pages }">
        <button class="page-link page-next" @click="nextPage">
          <i class="bi bi-chevron-right"></i>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "AppPagination",
  props: {
    page: {
      type: Number,
      required: true,
    },
    total_pages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pageList() {
      const pageNumbers = [];
      const maxVisiblePages = 2;

      if (this.total_pages <= maxVisiblePages * 2 + 1) {
        pageNumbers.push(
          ...Array.from({ length: this.total_pages }, (_, i) => i + 1)
        );
      } else {
        const page = this.page;
        const lastPage = this.total_pages;

        if (page <= maxVisiblePages) {
          pageNumbers.push(
            ...Array.from({ length: maxVisiblePages * 2 + 1 }, (_, i) => i + 1)
          );
        } else if (page >= lastPage - maxVisiblePages) {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => lastPage - maxVisiblePages * 2 + i
            )
          );
        } else {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => page - maxVisiblePages + i
            )
          );
        }
      }

      return pageNumbers;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    previousPage() {
      if (this.page > 1) {
        this.$emit("page-change", this.page - 1);
        this.scrollToTop();
      }
    },
    nextPage() {
      if (this.page < this.total_pages) {
        this.$emit("page-change", this.page + 1);
        this.scrollToTop();
      }
    },
    goToPage(page) {
      this.$emit("page-change", page);
      this.scrollToTop();
    },
  },
};
</script>
<style scoped>
.wr-pagination .pagination .page-item {
  margin: 10px;
}
.wr-pagination button:focus {
  box-shadow: unset;
}
.wr-pagination .pagination .page-item .page-link {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  font-size: 0.75rem;
  background: transparent;
  border: unset;
  color: var(--wr-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wr-pagination .pagination .page-item .page-link.page-prev,
.wr-pagination .pagination .page-item .page-link.page-next {
  background: var(--wr-light);
  box-shadow: var(--wr-shadow-1);
}
.wr-pagination .pagination .page-item.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.wr-pagination .pagination .page-item.active .page-link {
  background: var(--wr-primary-2);
  color: #fff;
}
</style>
