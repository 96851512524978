const state = {
  theme: "light_theme",
};

const getters = {
  theme: (state) => state.theme,
};

const mutations = {
  setTheme(state, theme) {
    state.theme = theme;
    document.body.classList.toggle("light_theme", theme === "light_theme");
    document.body.classList.toggle("dark_theme", theme === "dark_theme");
  },
};

const actions = {
  TOGGLE_THEME({ commit, state }) {
    const newTheme =
      state.theme === "light_theme" ? "dark_theme" : "light_theme";
    commit("setTheme", newTheme);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
