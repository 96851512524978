<template>
  <button
    type="button"
    class="wr-button wr-button--small wr-button--primary-muted"
  >
    <i class="bi bi-caret-left-fill"></i> {{ buttonTitle }}
  </button>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    buttonTitle: {
      type: String,
      default: "Back",
    },
  },
};
</script>

<style></style>
