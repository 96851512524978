import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_PROJECTS({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
        start_date = "",
        end_date = "",
        side = "",
        userId,
      } = payload ?? {};
      let requestUrl = `/admin/projects/all`;
      if (side == "admin") {
        requestUrl = `/admin/staff/${userId}/projects`;
      }
      if (side == "user") {
        requestUrl = `/admin/user/${userId}/projects`;
      }
      const { data } = await axiosService.get(requestUrl, {
        params: {
          status,
          page,
          per_page,
          search,
          start_date,
          end_date,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PROJECT_CATEGORIES({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
      } = payload ?? {};
      const { data } = await axiosService.get(`/admin/projects/categories`, {
        params: {
          status,
          page,
          per_page,
          search,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_PROJECT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/projects/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_CATEGORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin/projects/category/${payload}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_CATEGORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/projects/category/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_SUBCATEGORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/projects/category/sub/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_CATEGORY({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this category? This can cause unintended issues for projects under this category"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/admin/projects/category/${payload}/delete`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_SUBCATEGORY({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this subcategory? This can cause unintended issues for projects under this subcategory"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/admin/projects/category/sub/${payload}/delete`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async TOGGLE_CATEGORY_STATUS({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        `Are you sure you want to ${payload.status} this category?`
      );
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/projects/category/${payload.id}/status`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_CATEGORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/admin/projects/category/${payload.id}/edit`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_SUBCATEGORY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/admin/projects/category/sub/${payload.id}/edit`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PROJECTS_OVERVIEW({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/projects/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ASSIGN_PROJECT({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to assign this project to this staff?"
      );
      const { projectId, assignedToId } = payload;
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/project/${projectId}/assign`,
          { assignedToId }
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ACCEPT_PROJECT({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to accept this project?"
      );
      const { projectId } = payload;
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/project/${projectId}/accept`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REJECT_PROJECT({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to reject this project?"
      );
      const { projectId, comment } = payload;
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/project/${projectId}/reject`,
          { comment }
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async SEND_INVOICE({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to send this invoice?"
      );
      const { projectId, discount, totalPrice, items } = payload;
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/project/${projectId}/invoice/send`,
          { discount, totalPrice, items }
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async SUBMIT_PROJECT({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to submit this project?"
      );
      const { projectId, attachments, comment } = payload;
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/project/${projectId}/submission`,
          { attachments, comment }
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async MARK_SHIPPED({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to ship this project?"
      );
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/project/${payload.projectId}/shipped`,
          payload
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async RESOLVE_DISPUTE({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to resolve dispute for this project?"
      );
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/project/${payload.projectId}/resolve_dispute`,
          payload
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
