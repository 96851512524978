export const BASE_URL =
  window.location.href.indexOf("localhost") > -1
    ? "http://localhost:3333/api/v1"
    : "https://writeright-backend.ben.com.ng/api/v1";
export const SOCKET_URL =
  window.location.href.indexOf("localhost") > -1
    ? "http://localhost:3333/"
    : "https://writeright-backend.ben.com.ng/";
export const GAUTH_CLIENT_KEY =
  "1021579946778-a02h27kn4n39uflhefp94ku497d8jh72.apps.googleusercontent.com";
export const PAYSTACK_PUBLIC_KEY =
  "pk_test_4a4b3f379b34921c28d5b9fb508ec89860afda36";
