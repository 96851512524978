import { AuthRoutes } from "./auth";
import { DashboardRoutes } from "./dashboard";
const routes = [
  AuthRoutes,
  DashboardRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];
export default routes;
