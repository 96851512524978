<template>
  <main>
    <router-view v-slot="{ Component }">
      <transition name="opacity" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
import { useSeoMeta, useHead } from "unhead";
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
export default {
  name: "App",
  setup() {
    useHead({
      titleTemplate: "%s - WriteRight",
    });
    useSeoMeta({
      title: "Admin",
      description: "WriteRight",
      ogDescription: "WriteRight",
      ogTitle: "WriteRight",
    });
    const store = useStore();
    const currentTheme = computed(() => {
      return store.getters.theme || null;
    });
    onMounted(() => {
      if (currentTheme.value == "dark_theme") {
        document.body.classList.add("dark_theme");
      } else {
        document.body.classList.add("light_theme");
      }
    });
  },
};
</script>

<style></style>
