<template>
  <div class="dropdown wr-dropdown">
    <a
      href="javascript:void(0)"
      class="wr-bg--white p-2 p-lg-3 wr-radius-1 d-flex align-items-center dropdown-toggle text-decoration-none wr-button wr-button__input"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :style="{ color: selectedItem && selectedItem.colorCode }"
    >
      <span v-if="selectedItem">{{ selectedItem.name }}</span>
      <span v-else class="opacity-50">Select Option</span>
      <i class="bi bi-chevron-down wr-text__body-large wr-dropdown__icon"></i>
    </a>
    <ul class="dropdown-menu w-100">
      <div
        class="input-group mb-3 wr-input-group wr-input-group--small"
        v-if="dropdownItems.length > 10"
      >
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          v-model="searchQuery"
        />
      </div>
      <li v-for="(item, index) in filteredItems" :key="index">
        <a
          class="dropdown-item text-capitalize d-flex align-items-center justify-content-between"
          href="javascript:void(0)"
          @click="chooseItem(item)"
        >
          {{ item.name }}
          <i
            class="bi bi-check2 wr-text--primary"
            v-if="selectedItem && selectedItem.value === item.value"
          ></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    dropdownItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItem: null,
      searchQuery: "",
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return this.dropdownItems;
      } else {
        return this.dropdownItems.filter((item) =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
  },
  methods: {
    chooseItem(item) {
      this.selectedItem = item;
      this.searchQuery = "";
      this.$emit("itemSelected", item);
    },
  },
};
</script>

<style></style>
