import axios from "axios";
import store from "@/store";
import router from "@/router";
import { BASE_URL } from "@/config";
import { useToast } from "vue-toastification";
import { dashboardPaths } from "@/router/path.route";
const toast = useToast();
const axiosService = axios.create({
  baseURL: BASE_URL,
});
axiosService.interceptors.request.use(
  (config) => {
    const token = store?.state?.auth?.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosService.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    console.log(err.config);
    const originalConfig = err.config;
    console.log(originalConfig.url);
    if (!originalConfig.url.includes("/login") && err.response) {
      if (err.response.status === 401) {
        store.dispatch("LOGOUT_USER");
        toast.error("Session Expired. Please login");
      }
    }
    return Promise.reject(err);
  }
);

export default axiosService;
