import { dashboardPaths, DASHBOARD_BASE } from "../path.route";
import {
  allPermissions,
  adminPermissions,
  financePermissions,
  writerPermissions,
} from "@/models/roles.models";

console.log(dashboardPaths["overview"]);

export const DashboardRoutes = {
  path: DASHBOARD_BASE,
  name: "Dashboard",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/DashboardLayout.vue"),
  children: [
    {
      path: dashboardPaths["unauthorized"],
      name: "Unauthorized",
      component: () => import("@/views/dashboard/Unauthorized.vue"),
      // meta: {
      //   permissions: adminPermissions,
      // },
    },
    {
      path: dashboardPaths["categories"],
      name: "Categories",
      component: () => import("@/views/dashboard/Categories.vue"),
      meta: {
        permissions: adminPermissions,
      },
    },
    {
      path: dashboardPaths["customers"],
      name: "AllUsers",
      component: () => import("@/views/dashboard/Customers.vue"),
      meta: {
        permissions: adminPermissions,
      },
      children: [
        {
          path: dashboardPaths["customers"],
          name: "Users",
          component: () => import("@/views/dashboard/Customers/Index.vue"),
        },
        {
          path: dashboardPaths["single_customer"](":id"),
          name: "User",
          component: () => import("@/views/dashboard/Customers/_id.vue"),
        },
      ],
    },
    {
      path: dashboardPaths["messenger"],
      name: "Messenger",
      component: () => import("@/views/dashboard/Messenger.vue"),
      meta: {
        permissions: writerPermissions,
      },
    },
    {
      path: dashboardPaths["overview"],
      name: "Overview",
      component: () => import("@/views/dashboard/Overview.vue"),
      meta: {
        permissions: allPermissions,
      },
    },
    {
      path: dashboardPaths["payments"],
      name: "Payments",
      component: () => import("@/views/dashboard/Payments.vue"),
      meta: {
        permissions: financePermissions,
      },
    },
    {
      path: dashboardPaths["projects"],
      name: "Projects",
      component: () => import("@/views/dashboard/Projects.vue"),
      meta: {
        permissions: writerPermissions,
      },
    },
    {
      path: dashboardPaths["settings"],
      name: "Preferences",
      component: () => import("@/views/dashboard/Settings.vue"),
      meta: {
        permissions: adminPermissions,
      },
    },
    {
      path: dashboardPaths["staff"],
      name: "AllStaff",
      component: () => import("@/views/dashboard/Staff.vue"),
      meta: {
        permissions: adminPermissions,
      },
      children: [
        {
          path: dashboardPaths["staff"],
          name: "Staff",
          component: () => import("@/views/dashboard/Staff/Index.vue"),
        },
        {
          path: dashboardPaths["single_staff"](":id"),
          name: "Single",
          component: () => import("@/views/dashboard/Staff/_id.vue"),
        },
      ],
    },
  ],
};
