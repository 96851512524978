import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_STAFF({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
        role = "",
      } = payload ?? {};
      const { data } = await axiosService.get(`/admin/staff/all`, {
        params: {
          status,
          page,
          per_page,
          search,
          role,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_STAFF_STATS({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/staff/stats`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_ROLES({ commit, state }, payload) {
    try {
      const { page = 1, per_page = 100, search = "" } = payload ?? {};
      const { data } = await axiosService.get(`/admin/staff/roles`, {
        params: {
          page,
          per_page,
          search,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PERMISSIONS({ commit, state }, payload) {
    try {
      const { page = 1, per_page = 10, search = "" } = payload ?? {};
      const { data } = await axiosService.get(
        `/admin/staff/roles/permissions`,
        {
          params: {
            page,
            per_page,
            search,
          },
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_ROLE({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this role?"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/admin/staff/role/${payload}`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_ROLE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/staff/role/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PROFILE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/admin/staff/profile/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PASSWORD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/admin/staff/password/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REQUEST_EMAIL_UPDATE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/staff/profile/email/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async VERIFY_EMAIL_UPDATE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/staff/profile/email/verify`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_STAFF({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/admin/staff/new`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_STAFF({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/staff/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_STAFF_SPEND({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin/staff/${payload}/projects/profit`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async RESET_ADMIN_PASSWORD({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to reset password for this account?"
      );
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/staff/${payload}/reset_password`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CHANGE_STAFF_ROLE({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to change the role of this staff?"
      );
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/staff/${payload.id}/change_role`,
          {
            roleId: payload.role,
          }
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async TOGGLE_SUSPENSION({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        `Are you sure you want to ${payload.action} this staff?`
      );
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/staff/${payload.id}/toggle_suspension`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  getters,
  mutations,
  actions,
};
