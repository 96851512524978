<template>
  <div :class="[isFull ? 'w-100' : 'd-flex align-items-center']">
    <div class="dropdown wr-dropdown" v-if="selectedCountry">
      <a
        href="javascript:void(0)"
        class="d-inline-flex align-items-center dropdown-toggle text-decoration-none wr-text__small wr-text--dark"
        :class="[isFull && 'w-100']"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex align-items-center">
            <div class="me-1">
              <span
                class="fi ap-flag-icon--small"
                :class="['fi-' + selectedCountry.code.toLowerCase()]"
              ></span>
            </div>
            <div class="wr-text__smaller wr-text--dark opacity-75">
              {{ selectedCountry[itemToShow] }}
            </div>
          </div>
          <i class="bi bi-caret-down-fill wr-text__tiny"></i>
        </div>
      </a>
      <div
        v-show="!isDisabled"
        class="dropdown-menu p-2 dropdown-menu--small"
        :class="{
          'dropdown-menu-start': menuPosition == 'start',
          'dropdown-menu-end': menuPosition == 'end',
        }"
      >
        <div
          class="input-group wr-input-group wr-input-group--small mb-2"
          v-if="countries.length > 10"
        >
          <input
            type="text"
            class="form-control ap-form-input"
            placeholder="Search"
            v-model="searchQuery"
          />
        </div>
        <div
          v-for="country in filteredList"
          :key="country.code"
          class="d-flex align-items-center py-3 cursor--pointer ap-text--dark dropdown-item"
          @click="setCountry(country)"
          :class="[
            selectedCountry &&
              selectedCountry.code === country.code &&
              'ap-bg--grey-2',
          ]"
        >
          <div class="me-2">
            <span
              class="fi mr-2"
              :class="['fi-' + country.code.toLowerCase()]"
            ></span>
          </div>
          <div>
            <div class="ap-body--12 opacity-75">
              {{ country[labelToShow] }}
            </div>
            <div class="ap-body--12 opacity-75">
              {{ country[itemToShow] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import countries from "@/models/countries.models.js";
export default {
  name: "CountrySelect",
  props: {
    itemToShow: {
      type: String,
      default: "phone",
    },
    labelToShow: {
      type: String,
      default: "name",
    },
    menuPosition: {
      type: String,
      default: "",
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    defaultCountry: {
      type: String,
      default: "NG",
    },
  },
  emits: ["countrySelected"],
  setup(props, { emit }) {
    const defaultCountry = countries.find(
      (country) => country.code === props.defaultCountry
    );
    const selectedCountry = ref(null);
    const searchQuery = ref("");
    const setCountry = (country) => {
      selectedCountry.value = country;
      searchQuery.value = "";
      emit("countrySelected", country);
    };
    const filteredList = computed(() => {
      const searchTerm = searchQuery.value.trim().toLowerCase();
      if (countries.length === 0) return;
      const availableCountries = countries || [];
      if (searchTerm !== "") {
        return availableCountries
          .filter((c) => {
            const nameMatch =
              c.name && c.name.toLowerCase().includes(searchTerm);
            const codeMatch =
              c.code && c.code.toLowerCase().includes(searchTerm);
            const code3Match =
              c.code3 && c.code3.toLowerCase().includes(searchTerm);

            return nameMatch || codeMatch || code3Match;
          })
          .sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
          })
          .reduce((uniqueList, country) => {
            const existingCountry = uniqueList.find(
              (c) => c.code === country.code && c.code3 === country.code3
            );
            if (!existingCountry) {
              uniqueList.push(country);
            }
            return uniqueList;
          }, []);
      }

      return availableCountries
        .sort((a, b) => {
          const nameA = a.name || "";
          const nameB = b.name || "";
          return nameA.localeCompare(nameB);
        })
        .reduce((uniqueList, country) => {
          const existingCountry = uniqueList.find(
            (c) => c.code === country.code && c.code3 === country.code3
          );
          if (!existingCountry) {
            uniqueList.push(country);
          }
          return uniqueList;
        }, []);
    });
    onMounted(async () => {
      setCountry(defaultCountry);
    });

    return {
      countries,
      setCountry,
      selectedCountry,
      searchQuery,
      filteredList,
    };
  },
};
</script>

<style></style>
