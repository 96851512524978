import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_CUSTOMERS({ commit, state }, payload) {
    try {
      const {
        filter = "",
        page = 1,
        per_page = 10,
        search = "",
        start_date = "",
        end_date = "",
      } = payload ?? {};
      const { data } = await axiosService.get(`/admin/users/all`, {
        params: {
          filter,
          page,
          per_page,
          search,
          start_date,
          end_date,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_CUSTOMER({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/user/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_CUSTOMER_SPEND({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin/user/${payload}/amount_spent`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_CUSTOMER_STATS({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/users/stats`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async EXPORT_USERS({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/users/export`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async TOGGLE_USER_SUSPENSION({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        `Are you sure you want to ${payload.action} this customer?`
      );
      if (confirmed) {
        const { data } = await axiosService.put(
          `/admin/user/${payload.id}/toggle_suspension`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async RESET_USER_PASSWORD({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to reset password for this account?"
      );
      if (confirmed) {
        const { data } = await axiosService.post(
          `/admin/user/${payload}/reset_password`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  getters,
  mutations,
  actions,
};
