export const DASHBOARD_BASE = "/dashboard";
export const AUTH_BASE = "/auth";

export const dashboardPaths = {
  overview: `${DASHBOARD_BASE}/overview`,
  projects: `${DASHBOARD_BASE}/projects`,
  customers: `${DASHBOARD_BASE}/customers`,
  single_customer: (id) => `${DASHBOARD_BASE}/customer/${id}`,
  staff: `${DASHBOARD_BASE}/staff`,
  single_staff: (id) => `${DASHBOARD_BASE}/staff/${id}`,
  payments: `${DASHBOARD_BASE}/payments`,
  settings: `${DASHBOARD_BASE}/settings`,
  categories: `${DASHBOARD_BASE}/categories`,
  messenger: `${DASHBOARD_BASE}/messenger`,
  unauthorized: `${DASHBOARD_BASE}/unauthorized`,
  // set_project_delivery: (id) =>
  //   `${DASHBOARD_BASE}/project/${id}/delivery_method`,
};

export const authPaths = {
  login: `${AUTH_BASE}/login`,
};
