<template>
  <div>
    <span v-if="!isTruncated">{{ text }}</span>
    <span v-else
      >{{ truncatedText
      }}<a
        href="javascript:void(0)"
        class="wr-text--primary-2 text-decoration-none wr-text__small"
        @click.prevent="expandText"
        v-if="showReadMore"
      >
        Read more</a
      ></span
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      isTruncated: true,
    };
  },
  computed: {
    truncatedText() {
      if (!this.text) return this.text;
      if (this.isTruncated && this.showReadMore) {
        return this.text.slice(0, this.maxLength) + "...";
      } else {
        return this.text;
      }
    },
    showReadMore() {
      return this.text.length > this.maxLength;
    },
  },
  methods: {
    expandText() {
      this.isTruncated = false;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
