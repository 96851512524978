<template>
  <section class="wr-auth-wrapper">
    <div class="container">
      <div class="d-flex justify-content-center mt-4 mb-5">
        <AppLogo />
      </div>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </section>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style>
.wr-auth-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 4rem;
  background: var(--wr-grey-2);
}
.wr-auth-wrapper .wr-auth-wrapper__content {
  background: var(--wr-white);
  padding: 2rem;
  border-radius: 24px;
  min-height: 400px;
}
</style>
