<template>
  <div>
    <div
      class="align-items-center text-start cursor--pointer wr-user-info"
      v-if="userData"
      @click="viewUser"
      :class="{
        'd-flex': alignment == 'flex' || (USER && USER._id === userData?._id),
        'd-block': alignment == 'block',
        'wr-text__smaller': size == 'small',
      }"
    >
      <div class="me-1">
        <UserAvatar :imagePath="userData?.profilePic" size="smaller" />
      </div>
      <div
        v-if="USER && USER._id === userData?._id"
        class="wr-text--semibold text-capitalize"
      >
        {{ USER.firstName }} (you)
      </div>
      <div v-else>
        <div class="text-capitalize wr-text--semibold">
          {{ userData?.firstName || "N/A" }}
          {{ shortenString(userData?.lastName, 8) || "N/A" }}
        </div>
        <div
          class="wr-text__smaller wr-text--semibold opacity-50 break--word"
          v-if="userData?.email"
        >
          {{ shortenEmailAddress(userData?.email, 3) || "N/A" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dashboardPaths } from "@/router/path.route";
import { shortenEmailAddress, shortenString } from "@/helpers";
import { mapGetters } from "vuex";
export default {
  name: "UserInfoItem",
  props: {
    side: {
      type: String,
      required: true,
    },
    alignment: {
      type: String,
      default: "block",
    },
    size: {
      type: String,
      default: "regular",
    },
    userData: {
      type: Object,
      required: true,
    },
    noClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      USER: "getUser",
    }),
  },
  methods: {
    shortenEmailAddress,
    shortenString,
    viewUser() {
      if (this.noClick) return;
      if (this.side == "user") {
        this.$router.push(dashboardPaths["single_customer"](this.userData._id));
      } else {
        this.$router.push(dashboardPaths["single_staff"](this.userData._id));
      }
    },
  },
};
</script>

<style scoped>
.wr-user-info:hover {
  color: var(--wr-primary-2) !important;
  transition: 0.5s ease-in-out;
}
</style>
