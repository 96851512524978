<template>
  <div class="wr-dash-tabs">
    <a
      href="javascript:void(0)"
      v-for="item in tabItems"
      :key="item.value"
      @click="selectTab(item)"
      class="wr-dash-tabs__item"
      :class="[
        selectedItem &&
          selectedItem.value === item.value &&
          'wr-dash-tabs__item--active',
      ]"
    >
      <i class="me-2" :class="item.iconClass" v-if="item.iconClass"></i>
      {{ item.name }}
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DashboardTab",
  props: {
    tabItems: {
      type: Array,
      default: () => [],
    },
    defaultItem: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const selectedItem = ref(props.defaultItem);
    const selectTab = (tab) => {
      selectedItem.value = tab;
      emit("tabChanged", tab);
    };
    return {
      selectedItem,
      selectTab,
    };
  },
};
</script>

<style scoped>
.wr-dash-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--wr-grey-1);
}
.wr-dash-tabs .wr-dash-tabs__item {
  display: block;
  font-size: 1rem;
  color: var(--wr-dark);
  margin: 0 20px 0 0;
  text-decoration: none;
  padding: 0.6rem 0;
  transition: 0.6s ease-in-out;
  font-weight: var(--wr-fw-semibold);
  color: var(--wr-grey-1);
  border: unset;
  border-bottom: 3px solid transparent;
  opacity: 0.6;
}
.wr-dash-tabs__item.wr-dash-tabs__item--active {
  border-color: var(--wr-primary);
  color: var(--wr-primary);
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .wr-dash-tabs .wr-dash-tabs__item {
    font-size: 0.6875rem;
  }
}
</style>
