import AuthLayout from "@/layouts/AuthLayout";
import { authPaths } from "../path.route";
export const AuthRoutes = {
  path: "/",
  redirect: authPaths["login"],
  component: AuthLayout,
  children: [
    {
      path: authPaths["login"],
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
    },
  ],
};
