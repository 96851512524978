import dayjs from "dayjs";
import parsePhoneNumber from "libphonenumber-js";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";
import advancedFormat from "dayjs/plugin/advancedFormat";
import validator from "validator";
import countries from "@/models/countries.models";
import projectStatus from "@/models/status.models";
import { supportedCards } from "@/models/bank.models";
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);
export const shortenString = (str, maxLength = 16) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};
export function shortenStringAtCenter(str, maxLength = 24, ellipsis = "...") {
  if (str.length <= maxLength) {
    return str;
  }
  const ellipsisLength = ellipsis.length;
  const centerIndex = Math.floor((maxLength - ellipsisLength) / 2);
  const leftHalf = str.slice(0, centerIndex);
  const rightHalf = str.slice(str.length - centerIndex);
  return leftHalf + ellipsis + rightHalf;
}
export const formatCurrency = (number, currencyCode = "NGN") => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currencyCode,
  });

  return formatter.format(number);
};
export const formatTimeOnly = (date) => {
  return dayjs(date).format("LT");
};
export const formatDateOnly = (date, format = "DD MMM, YYYY") => {
  return dayjs(date).format(format);
};
export const advancedDateFormat = (date, format = "Do MMMM, YYYY") => {
  return dayjs(date).format(format);
};
export const generateUserAvatar = (name) => {
  return "https://api.dicebear.com/7.x/identicon/svg??seed=afriprize&backgroundColor=9747ff";
};
export const isStrongPassword = (password) => {
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /\d/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  return (
    uppercaseRegex.test(password) &&
    numberRegex.test(password) &&
    specialCharRegex.test(password)
  );
};
export const isCreditCard = (val) => {
  return validator.isCreditCard(val);
};
export const isValidPhoneNumber = (phone, region = "US") => {
  const phoneNumber = parsePhoneNumber(phone, `${region}`);
  console.log(
    `is valid ${region}?: ${
      phoneNumber && phoneNumber.isPossible() && phoneNumber.isValid()
        ? "yes"
        : "no"
    }`,
    phoneNumber
  );
  if (phoneNumber && phoneNumber?.country == region) {
    return phoneNumber.isPossible() && phoneNumber.isValid();
  } else {
    return false;
  }
};
export const returnPhoneNumber = (phone, region = "US") => {
  const phoneNumber = parsePhoneNumber(phone, `${region}`);
  if (phoneNumber) {
    return phoneNumber;
  } else {
    return false;
  }
};
export const predictPhoneNumber = (phone) => {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber;
  } else {
    return false;
  }
};
export const isValidUsername = (username) => {
  const isLengthValid = username.length >= 5 && username.length <= 20;
  const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(username);
  return isLengthValid && isAlphanumeric;
};

export const getRandomDarkColor = () => {
  const getRandomComponent = () =>
    Math.floor(Math.random() * 128)
      .toString(16)
      .padStart(2, "0");

  const red = getRandomComponent();
  const green = getRandomComponent();
  const blue = getRandomComponent();

  return `#${red}${green}${blue}`;
};
export const generateData = (
  count,
  range = {
    min: 0,
    max: 90,
  }
) => {
  let data = [];
  for (let i = 0; i < count; i++) {
    data.push({
      x: i + 1,
      y: Math.floor(Math.random() * (range.max - range.min + 1)) + range.min,
    });
  }
  return data;
};
export const generateStrongPassword = (length = 12) => {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*()-_+=<>?";

  const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

  let password = "";

  password += getRandomChar(uppercaseChars);
  password += getRandomChar(lowercaseChars);
  password += getRandomChar(numberChars);
  password += getRandomChar(specialChars);

  for (let i = password.length; i < length; i++) {
    password += getRandomChar(allChars);
  }

  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
};

const getRandomChar = (charSet) => {
  const randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet[randomIndex];
};
export const returnUserBehaviours = (val) => {
  const behaviours = [
    {
      name: "Normal",
      colorCode: "#0974EE",
    },
    {
      name: "Suspicious",
      colorCode: "#F89412",
    },
    {
      name: "Bad",
      colorCode: "#E64E60",
    },
  ];
  const currentBehaviour = behaviours.find(
    (x) => x.name.toLowerCase() == val.toLowerCase()
  );
  if (currentBehaviour) {
    return currentBehaviour;
  } else {
    return behaviours[0];
  }
};
export const fieldSet = (val, expects = "url") => {
  switch (expects) {
    case "url":
      return validator.isURL(val);
      break;
    default:
      return false;
      break;
  }
};
export const generateRandomCharacters = (minLength = 6) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  for (let i = 0; i < minLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
export const hasNoSpecialCharacters = (str) => {
  const specialCharacters = /[^a-zA-Z0-9]/;
  return !specialCharacters.test(str);
};
export const getCountryData = (code) => {
  const countryData = countries.find((val) => val.code === code.toUpperCase());
  if (countryData) {
    return countryData;
  }
};
export const getCardData = (brand) => {
  const data = supportedCards.find((val) => val.value.toLowerCase() === brand);
  if (data) {
    return data;
  }
};
export const getProjectStatus = (status) => {
  const data = projectStatus.find((val) => val.value === status);
  if (data) {
    return data;
  }
};
export const removeSpacesAndSpecialCharacters = (str) =>
  str.replace(/[^a-zA-Z0-9]/g, "");

export const shortenEmailAddress = (email, maxLength = 18) => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    const localPart = email.substring(0, atIndex);
    const domainPart = email.substring(atIndex);
    const shortenedLocalPart =
      localPart.length > maxLength
        ? localPart.substring(0, maxLength) + "..."
        : localPart;
    return shortenedLocalPart + domainPart;
  }
  return email;
};
export const downloadCsv = (csvData, name = "payments") => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}_${Date.now()}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDeadlineStatus = (date) => {
  if (!date) {
    return "No date provided";
  }

  const currentDate = new Date();
  const deadlineDate = new Date(date);
  const timeDifference = deadlineDate - currentDate;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference < 0) {
    return "Deadline passed";
  } else if (daysDifference === 0) {
    return "Deadline Today";
  } else {
    return `${daysDifference} days left until deadline`;
  }
};

export const splitAndFormatString = (text) => {
  const regex = /[A-Z]?[a-z]+/g;
  const formatedTitle = text.match(regex) || [];
  return formatedTitle.join(" ");
};
export const startDownload = (fileData) => {
  const { filename, filepath, mimetype } = fileData;
  const anchor = document.createElement("a");
  anchor.href = filepath;
  anchor.download = filename;
  if (mimetype.startsWith("image")) {
    anchor.setAttribute("target", "_blank");
  } else {
    anchor.style.display = "none";
    document.body.appendChild(anchor);
  }

  anchor.click();
  if (!mimetype.startsWith("image")) {
    document.body.removeChild(anchor);
  }
};
